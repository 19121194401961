import Vue from 'vue';
import Component from 'vue-class-component';
import Router from 'vue-router';
import MainLayout from '@/core/layouts/main-layout.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);

const Home = () => import('@/core/home/home.vue');
const Error = () => import('@/core/error/error.vue');
const Contact = () => import('@/core/contact/contact.vue');
const Post = () => import('@/core/post/post.vue');
const SinglePost = () => import('@/core/single-post/single-post.vue');
const Admission6 = () => import('@/core/admission/class6/admission-6.vue');
const Admission10 = () => import('@/core/admission/class10/admission-10.vue');

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: Error,
      meta: { error403: true }
    },

    {
      path: '',
      name: 'MainLayout',
      component: MainLayout,
      redirect: '/',
      children: [
        {
          path: '/',
          name: 'Trang chủ',
          component: Home,
          meta: {
            title: 'Trang chủ'
          }
        },
        {
          path: '/not-found',
          name: 'Trang không tồn tại',
          component: Error
        },
        {
          path: '/lien-he',
          name: 'Liên hệ',
          component: Contact,
          meta: {
            breadcrumbs: [
              {
                text: 'trang-chu',
                href: '/'
              },
              {
                active: true
              }
            ]
          }
        },
        {
          path: '/tuyen-sinh-lop-6',
          name: 'Tuyển sinh lớp 6',
          component: Admission6
        },
        {
          path: '/tuyen-sinh-lop-10',
          name: 'Tuyển sinh lớp 10',
          component: Admission10
        },
        {
          path: '/:key',
          component: Post,
          meta: {
            breadcrumbs: [
              {
                text: 'Trang chủ',
                href: '/'
              },
              {
                active: true
              }
            ]
          }
        },
        {
          path: '/:category/:key',
          name: 'Bài viết',
          component: SinglePost
        }

      ]
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
