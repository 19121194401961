import axios from 'axios';

export default class HomeService {
  public getSettings(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('api/home/settings')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findAllByCategory(params: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('api/home/news/category', {
          params,
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findAllNews(params: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('api/home/news', {
          params,
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findOneNews(id: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('api/home/news/' + id)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getNewsByKey(key: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('api/home/news/get', {
          params: { key },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
