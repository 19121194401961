var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "chat-box" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "chat-box-body" }, [
        _c("div", { staticClass: "chat-box-overlay" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "chat-logs" },
          _vm._l(_vm.chats, function (msg) {
            return _c("div", { class: `chat-msg ${msg.type}` }, [
              _c("div", { staticClass: "cm-msg-text" }, [
                _vm._v("\n            " + _vm._s(msg.message) + "\n          "),
              ]),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "chat-input" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.message,
              expression: "message",
            },
          ],
          attrs: {
            autocomplete: "off",
            type: "text",
            id: "chat-input",
            placeholder: "Nhập tin nhắn",
          },
          domProps: { value: _vm.message },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.onSendMessage.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.message = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "chat-button" }, [
          _c(
            "button",
            {
              staticClass: "chat-submit",
              attrs: { type: "button", id: "chat-submit" },
              on: { click: _vm.onSendMessage },
            },
            [_c("i", { staticClass: "fa-solid fa-paper-plane" })]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "btn btn-raised", attrs: { id: "chat-circle" } },
      [
        _c("div", { attrs: { id: "chat-overlay" } }),
        _vm._v(" "),
        _c("div", { staticClass: "chatbot-container" }, [
          _c("div", { attrs: { id: "chatbot" } }, [
            _c("div", { staticClass: "dot" }),
            _vm._v(" "),
            _c("div", { staticClass: "dot" }),
            _vm._v(" "),
            _c("div", { staticClass: "dot" }),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { id: "chatbot-corner" } }),
          _vm._v(" "),
          _c("div", { attrs: { id: "antenna" } }, [
            _c("div", { attrs: { id: "beam" } }),
            _vm._v(" "),
            _c("div", { attrs: { id: "beam-pulsar" } }),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "chat-box-header" }, [
      _c("div", { staticClass: "chat-box-logo" }, [
        _c("i", { staticClass: "fa-solid fa-robot" }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "chat-box-toggle" }, [
        _c("i", { staticClass: "fa-solid fa-xmark" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }