
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PaginationCustom } from '@/entities/pagination-custom';
import { DOTS } from '@/constants';

@Component
export default class Pagination extends Vue {
  readonly dots = DOTS;
  @Prop() totalItems!: number;
  @Prop() page!: number;
  @Prop({ default: 20 }) pageSize!: number;
  @Prop({ default: 1 }) siblingCount!: number;

  pages: any[] = [];

  @Watch('page')
  onCurrentPageChanged(): void {
    this.rerender();
  }

  @Watch('pageSize')
  onPageSizeChanged(): void {
    this.rerender();
  }

  @Watch('totalItems')
  onTotalItemsChanged(): void {
    this.rerender();
  }

  created() {
    this.rerender();
  }

  rerender(page?: number) {
    const paginator = new PaginationCustom(this.totalItems, page ? page : this.page, this.pageSize, this.siblingCount);
    this.pages = paginator.pages;
  }

  onFirstPage() {
    return this.page === 1;
  }

  onLastPage() {
    return this.page === this.pages[this.pages.length - 1];
  }

  onPaging(page, isDisabled = false) {
    if (isDisabled) return;
    this.rerender(page);
    // emit change page event to parent component
    this.$emit('changePage', page);
  }
}
