import { Component, Inject, Vue } from 'vue-property-decorator';
import TranslationService from '@/locale/translation.service';
import * as $ from 'jquery';
import { MenuEntity } from '@/entities/menu-entity';

@Component({})
export default class JhiNavbar extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;

  public version = 'v' + VERSION;
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;

  // public isHome = this.$route.path == '' || this.$route.path == '/';
  public get isHome(): boolean {
    return this.$route.path == '' || this.$route.path == '/';
  }

  private menus: MenuEntity[] = [
    new MenuEntity({
      name: 'Giới thiệu',
      children: [
        new MenuEntity({ name: 'Giới thiệu chung', href: '/bai-viet/gioi-thieu-chung' }),
        new MenuEntity({ name: 'Cơ cấu tổ chức', href: '/bai-viet/co-cau-to-chuc' }),
        new MenuEntity({ name: 'Thương hiệu', href: '/bai-viet/thuong-hieu' }),
      ],
    }),
    new MenuEntity({
      name: 'Tin tức',
      children: [
        new MenuEntity({ name: 'Tin tức', href: '/tin-tuc' }),
        new MenuEntity({ name: 'Sự kiện', href: '/su-kien' }),
        new MenuEntity({ name: 'Thông báo', href: '/thong-bao' }),
      ],
    }),
    new MenuEntity({ name: 'Góc phụ huynh', href: '/bai-viet/goc-phu-huynh' }),
    new MenuEntity({ name: 'Tuyển dụng', href: '/tuyen-dung' }),
    new MenuEntity({ name: 'Liên hệ', href: '/lien-he' }),
    new MenuEntity({
      name: 'Tuyển sinh',
      class: 'admission-nav',
      children: [
        new MenuEntity({ name: 'Tuyển sinh lớp 6', href: '/tuyen-sinh-lop-6' }),
        new MenuEntity({ name: 'Tuyển sinh lớp 10', href: '/tuyen-sinh-lop-10' }),
      ],
    }),
  ];

  created() {
    const currentLanguage = Object.keys(this.languages).includes(navigator.language) ? navigator.language : this.currentLanguage;
    this.translationService().refreshTranslation(currentLanguage);

    this.$nextTick(() => {
      function mobileNavToogle() {
        $('.mobile-nav-toggle').toggleClass('bi-list');
        $('.mobile-nav-toggle').toggleClass('bi-x');
        $('body').toggleClass('mobile-nav-active');
      }

      $('.mobile-nav-toggle').click(function () {
        mobileNavToogle();
      });

      $('#navmenu a').click(function () {
        mobileNavToogle();
      });

      $('.navmenu .toggle-dropdown').click(function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('active');
        $(this).parent().next().toggleClass('dropdown-active');
        e.stopImmediatePropagation();
      });
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }
}
