import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import '@/shared/config/dayjs';

@Component({
  components: {
    ribbon: Ribbon,
  },
})
export default class App extends Vue {
  public get loadingState() {
    return this.$store.getters.globalLoading;
  }
}
