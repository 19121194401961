export class MenuEntity {
  name: string;
  class?: string;
  href?: string;
  children?: MenuEntity[];

  constructor(data?: MenuEntity) {
    this.name = (data && data.name) || '';
    this.class = (data && data.class) || '';
    this.href = data && data.href;
    this.children = (data && data.children) || [];
  }
}
