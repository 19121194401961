var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "main",
    [
      _c("jhi-navbar"),
      _vm._v(" "),
      _c("div", { attrs: { id: "app-main" } }, [_c("router-view")], 1),
      _vm._v(" "),
      _c("jhi-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }