// Errors
export const PROBLEM_BASE_URL = 'https://www.jhipster.tech/problem';
export const EMAIL_ALREADY_USED_TYPE = `${PROBLEM_BASE_URL}/email-already-used`;
export const LOGIN_ALREADY_USED_TYPE = `${PROBLEM_BASE_URL}/login-already-used`;

export const DOTS = '...';

export const CATEGORY_POST = 'tin-tuc';
export const CATEGORY_EVENT = 'su-kien';
export const CATEGORY_NOTIFICATION = 'thong-bao';
export const CATEGORY_PAGE = 'bai-viet';

export const DEFAULT_BANNERS = [
  {
    title: 'Tiêu đề',
    content: 'Nội dung',
    button: 'Tìm hiểu thêm',
    href: '#',
  },
];

export const DEFAULT_OUTSTANDINGS = [
  {
    icon: "<i class='fa-solid fa-school'></i>",
    title: 'Tiêu đề',
    content: 'Nội dung',
  },
  {
    icon: "<i class='fa-solid fa-school'></i>",
    title: 'Tiêu đề',
    content: 'Nội dung',
  },
  {
    icon: "<i class='fa-solid fa-school'></i>",
    title: 'Tiêu đề',
    content: 'Nội dung',
  },
];

export const DEFAULT_MESSAGE_FROM_GRAND_MASTER = {
  title: 'Tiêu đề',
  description: 'Nội dung',
  messages: [
    { value: '0%', content: 'Nội dung' },
    { value: '0%', content: 'Nội dung' },
    { value: '0%', content: 'Nội dung' },
    { value: '0%', content: 'Nội dung' },
  ],
};

export const DEFAULT_EDUCATION_PROGRAM = {
  title: 'Tiêu đề',
  content: 'Nội dung',
};

export const DEFAULT_PSYCHOLOGY_ROOM = {
  title: 'Tiêu đề',
  confession: {
    title: 'Tiêu đề',
    button: 'Đặt lịch ngay',
    steps: [
      {
        title: 'Tiêu đề',
        description: 'Nội dung',
      },
      {
        title: 'Tiêu đề',
        description: 'Nội dung',
      },
      {
        title: 'Tiêu đề',
        description: 'Nội dung',
      },
    ],
  },
};

export const DEFAULT_SLOGAN = {
  title: 'Có chí thì nên',
};

export const DEFAULT_FOOTER = {
  schoolName: 'Trường THCS&THPT\nLương Thế Vinh',
  schoolInfo: {
    title: 'Liên hệ',
    phoneNumber: '024 3568 2603',
    addressList: [
      { address: 'Cơ sở 1 - Tân Triều: Thôn Yên Xã, Xã Tân Triều, Thanh Trì, Hà Nội' },
      { address: 'Cơ sở A - Cầu GIấy: Số 35 Đinh Núp, Trung Hòa, Cầu Giấy, Hà Nội' },
    ],
  },
  social: {
    title: 'Các nền tảng mạng xã hội khác',
    facebook: '',
    youtube: '',
    instagram: '',
  },
};
