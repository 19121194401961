var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ul",
    { staticClass: "pagination mb-2" },
    [
      _c(
        "li",
        { staticClass: "page-item", class: { disabled: _vm.onFirstPage() } },
        [
          _c(
            "a",
            {
              staticClass: "page-link",
              on: {
                click: function ($event) {
                  _vm.onPaging(_vm.page - 1, _vm.onFirstPage())
                },
              },
            },
            [_c("i", { staticClass: "fa-solid fa-arrow-left-long" })]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.pages, function (p, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "page-item",
            class: { active: p === _vm.page },
          },
          [
            p === _vm.dots
              ? _c("a", { staticClass: "page-link" }, [_vm._v("...")])
              : _c(
                  "a",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function ($event) {
                        return _vm.onPaging(p, p === _vm.page)
                      },
                    },
                  },
                  [_vm._v(_vm._s(p))]
                ),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "li",
        { staticClass: "page-item", class: { disabled: _vm.onLastPage() } },
        [
          _c(
            "a",
            {
              staticClass: "page-link",
              on: {
                click: function ($event) {
                  _vm.onPaging(_vm.page + 1, _vm.onLastPage())
                },
              },
            },
            [_c("i", { staticClass: "fa-solid fa-arrow-right-long" })]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }