var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("footer", { staticClass: "home-footer" }, [
    _c("div", { staticClass: "footer-inner" }, [
      _c("div", { staticClass: "footer-top" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "logo" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: require("../../../content/images/logo-white.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "h5",
                  {
                    staticClass: "school-name",
                    domProps: { innerHTML: _vm._s(_vm.footer.schoolName) },
                  },
                  [
                    _vm._v(
                      "\n                Trường THCS&THPT\n                "
                    ),
                    _c("br"),
                    _vm._v("\n                Lương Thế Vinh\n              "),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "school-info" }, [
                _c(
                  "h5",
                  {
                    staticClass: "title",
                    domProps: {
                      innerHTML: _vm._s(_vm.footer.schoolInfo.title),
                    },
                  },
                  [_vm._v("Liên hệ")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "phone-number",
                    domProps: {
                      innerHTML: _vm._s(_vm.footer.schoolInfo.phoneNumber),
                    },
                  },
                  [_vm._v("024 3568 2603")]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "address-list" },
                  _vm._l(
                    _vm.footer.schoolInfo.addressList,
                    function (item, idx) {
                      return _c(
                        "li",
                        {
                          staticClass: "address-item",
                          domProps: { innerHTML: _vm._s(item.address) },
                        },
                        [
                          _vm._v(
                            "\n                  Cơ sở 1 - Tân Triều: Thôn Yên Xã, Xã Tân Triều, Thanh Trì, Hà Nội\n                "
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "social" }, [
                _c(
                  "h5",
                  {
                    staticClass: "title",
                    domProps: { innerHTML: _vm._s(_vm.footer.social.title) },
                  },
                  [_vm._v("Các nền tảng mạng xã hội khác")]
                ),
                _vm._v(" "),
                _c("ul", { staticClass: "social-list" }, [
                  _c("li", [
                    _c("a", { attrs: { href: _vm.footer.social.facebook } }, [
                      _c("i", { staticClass: "fa-brands fa-facebook-f" }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("a", { attrs: { href: _vm.footer.social.youtube } }, [
                      _c("i", { staticClass: "fa-brands fa-youtube" }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("a", { attrs: { href: _vm.footer.social.instagram } }, [
                      _c("i", { staticClass: "fa-brands fa-instagram" }),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "footer-bottom" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4 text-center text-white" }, [
            _c("div", [
              _vm._v("LTV SCHOOL Website - © 2022 All Rights Reserved"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c("ul", { staticClass: "navs" }, [
              _c("li", [_c("a", [_vm._v("About")])]),
              _vm._v(" "),
              _c("li", [_c("a", [_vm._v("FAQ")])]),
              _vm._v(" "),
              _c("li", [_c("a", [_vm._v("Privacy Policy")])]),
              _vm._v(" "),
              _c("li", [_c("a", [_vm._v("Sitemap")])]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4 text-center" }, [
            _vm._v("Made with by CodeWind Team"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }