// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { BootstrapVue, IconsPlugin, ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import AlertService from './shared/alert/alert.service';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import vSelect from 'vue-select';
import VueLazyload from 'vue-lazyload';

import '../content/scss/breadcrumb.scss';
import '../content/css/animate.css';

/*CUSTOM*/
import 'bootstrap/dist/css/bootstrap.min.css';
import '../content/vendor/bootstrap/css/bootstrap.min.css';
import '../content/vendor/bootstrap-icons/bootstrap-icons.css';
import '../content/vendor/glightbox/css/glightbox.min.css';
import '../content/vendor/swiper/swiper-bundle.min.css';
import '../content/css/main.css';
import 'aos/dist/aos.css';
import 'dayjs/locale/vi';

// Import Swiper styles
import 'swiper/css';

import TranslationService from '@/locale/translation.service';
import Breadcrumb from '@/core/breadcrumb/breadcrumb.vue';
import GlobalLoading from '@/common/global-loading.vue';
import Pagination from '@/common/pagination.vue';
import dayjs from 'dayjs';
import HomeService from '@/services/home.service';
import AOS from 'aos';
import PureCounter from '@srexi/purecounterjs';

/* tslint:disable */

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.use(VueAwesomeSwiper);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

const loadimage = require('../content/images/no_image.jpg');
const errorimage = require('../content/images/no_image.jpg');
Vue.use(VueLazyload, {
  error: errorimage,
  loading: loadimage,
  attempt: 1,
});
Vue.component('v-select', vSelect);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
Vue.component('breadcrumb', Breadcrumb);
Vue.component('global-loading', GlobalLoading);
Vue.component('pagination', Pagination);
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);

dayjs.locale('vi');

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  } else {
    store.commit('setGlobalLoading', true);

    if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
      /*accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
        if (!value) {
          sessionStorage.setItem('requested-url', to.fullPath);
          next('/forbidden');
        } else {
          next();
        }
      });*/
    } else {
      // no authorities, so just proceed
      next();
    }

    setTimeout(() => {
      store.commit('setGlobalLoading', false);
    }, 1000);
  }
});

const DEFAULT_TITLE = 'Trường THCS&THPT Lương Thế Vinh';
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.name || DEFAULT_TITLE;
    AOS.init({
      delay: 200,
      duration: 600,
      easing: 'ease-in-out',
      once: false,
      mirror: false,
    });
    new PureCounter({
      once: false,
    });
  });
});

/* tslint:disable */
const vue = new Vue({
  el: '#app',
  mounted() {
    AOS.init({
      delay: 200,
      duration: 600,
      easing: 'ease-in-out',
      once: false,
      mirror: false,
    });
  },
  components: { App },
  template: '<App/>',
  router,
  provide: {
    translationService: () => translationService,

    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    alertService: () => new AlertService(),
    homeService: () => new HomeService(),
  },
  i18n,
  store,
});

setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Store logged out state.
      store.commit('logout');
      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        /*loginService.openLogin(vue);*/
        return;
      }
    }
    console.log('Unauthorized!');
    return Promise.reject(error);
  },
  error => {
    console.log('Server error!');
    return Promise.reject(error);
  }
);
