import { Module } from 'vuex';

export interface GlobalStateStorable {
  globalLoading: boolean;
}

export const defaultGlobalState: GlobalStateStorable = {
  globalLoading: true,
};

export const globalStore: Module<GlobalStateStorable, any> = {
  state: { ...defaultGlobalState },
  getters: {
    globalLoading: state => state.globalLoading,
  },
  mutations: {
    setGlobalLoading(state, loading) {
      state.globalLoading = loading;
    },
  },
};
