
import Vue from 'vue';
import Component from 'vue-class-component';
import * as $ from 'jquery';

@Component
export default class ChatBot extends Vue {
  public message = '';
  public chats = [
    { type: 'user', message: 'Hello' },
    { type: 'self', message: 'How are you?' },
    { type: 'user', message: 'Fine, thanks. And you' },
  ];

  created() {
    this.$nextTick(function () {
      $('#chat-circle').click(function () {
        $('#chat-circle').toggle('scale');
        $('.chat-box').toggle('scale');
      });

      $('.chat-box-toggle').click(function () {
        $('#chat-circle').toggle('scale');
        $('.chat-box').toggle('scale');
      });
    });
  }

  public onSendMessage() {
    if (this.message && this.message.length > 0) {
      this.chats.push({
        type: 'self',
        message: this.message,
      });

      this.message = '';
    }
  }
}
