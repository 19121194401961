
import Component from 'vue-class-component';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import Vue from 'vue';
import ChatBot from '@/core/chat-bot/chat-bot.vue';

@Component({
  components: {
    'jhi-navbar': JhiNavbar,
    'jhi-footer': JhiFooter,
    'chat-bot': ChatBot,
  },
})
export default class MainLayout extends Vue {}
