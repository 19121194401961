var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("nav", { staticClass: "ltv-breadcrumbs breadcrumbs" }, [
    _c(
      "ol",
      { staticClass: "breadcrumb container" },
      _vm._l(_vm.values, function (item) {
        return _c(
          "li",
          { staticClass: "breadcrumb-item", class: { active: item.active } },
          [
            !item.active
              ? _c("router-link", { attrs: { to: item.href } }, [
                  _vm._v(_vm._s(item.text)),
                ])
              : _c("span", [_vm._v(_vm._s(item.text))]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }