import { DOTS } from '@/constants';

export class PaginationCustom {
  totalPageCount: number;
  pageSize: number;
  pages: any[];
  siblingCount: any;

  constructor(totalItems, currentPage, pageSize = 20, siblingCount = 1) {
    let pages: any[] = [];
    const totalPageCount = totalItems === 0 ? 1 : Math.ceil(totalItems / pageSize);
    // totalPagesNumbers = siblingCount + first + last + current + 2 * DOT
    const totalPagesNumbers = siblingCount * 2 + 5;
    // show all pages
    if (totalPagesNumbers >= totalPageCount) {
      pages = this.range(1, totalPageCount);
    } else {
      const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
      const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

      const showLeftDots = leftSiblingIndex > 2;
      const showRightDots = rightSiblingIndex < totalPageCount - 2;
      // No left dots to show, but rights dots to be shown
      if (!showLeftDots && showRightDots) {
        const leftItemCount = 3 + 2 * siblingCount;
        const leftRange = this.range(1, leftItemCount);
        pages = [...leftRange, DOTS, totalPageCount];
      } else if (showLeftDots && !showRightDots) {
        // No right dots to show, but left dots to be shown
        const rightItemCount = 3 + 2 * siblingCount;
        const rightRange = this.range(totalPageCount - rightItemCount + 1, totalPageCount);
        pages = [1, DOTS, ...rightRange];
      } else if (showLeftDots && showRightDots) {
        // Both left and right dots to be shown
        const middleRange = this.range(leftSiblingIndex, rightSiblingIndex);
        pages = [1, DOTS, ...middleRange, DOTS, totalPageCount];
      }
    }
    // update object instance with all pager properties required by the view
    this.pageSize = pageSize;
    this.siblingCount = siblingCount;
    this.pages = pages;
    this.totalPageCount = totalPageCount;
  }

  public range(start, end) {
    return Array(end - start + 1)
      .fill(null)
      .map((_, idx) => start + idx);
  }
}

export interface IPaginationResponse<T> {
  content: ({ index: number } & T)[];
  hasNext: boolean;
  hasPrevious: boolean;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}
