var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "loading-wrap",
      },
      [_vm._m(0)]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading",
          },
        ],
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "loading-spinner" }, [
      _c("img", {
        staticClass: "img-fluid zoom-in-zoom-out",
        attrs: { src: require("../../content/images/logo.png"), alt: "Logo" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }