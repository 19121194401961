import { Component, Vue } from 'vue-property-decorator';
import { DEFAULT_FOOTER } from '@/constants';

@Component
export default class JhiFooter extends Vue {
  public footer: any = DEFAULT_FOOTER;

  created() {
    this.footer = this.getOrDefault(localStorage.getItem('footer'), DEFAULT_FOOTER);
  }

  public getOrDefault(data, defaultValue) {
    if (data == null) return defaultValue;
    data = data.replaceAll('\\n', '<br/>');
    return JSON.parse(data);
  }
}
